.T-page {
  height: 800px;
  width: 90vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #078bb3;
  margin-top: 2em;
  border: 1px solid transperent;
  border-radius: 120px;
  box-shadow: 10px 10px;
}
.text {
  text-align: start;
}
.pic {
  height: 20em;
  width: 20em;
  border: 1px solid transperent;
  border-radius: 10vh;
  object-fit: cover;
  text-align: center;
}
.P-container {
  width: 90%;
}
.Name {
  font-size: 7vmin;
  color: white;
  font-weight: 500;
}
.T-description {
  font-size: 1.5em;
  color: bisque;
  width: 99%;
  text-align: start;
}
@media (max-width: 750px) {
  .T-page {
    flex-direction: column;
  }
  .Name {
    font-size: 3em;
  }
  .T-description {
    font-size: 1.3em;
  }
  .pic {
    width: 50vw;
  }
}
/* @media (max-height: 950px) {
  .pic {
    height: 35vh;
    width: 35vw;
  }
} */
