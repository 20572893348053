.InfoPage {
  margin-top: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 90vh;
}
.p-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.resume {
  object-fit: contain;
}
.r-info {
  padding: 2em;
  font-size: 2vmin;
  align-items: center;
  justify-content: center;

  background-color: #078bb3;
  margin-top: 2em;
  border: 1px solid transperent;
  border-radius: 120px;
  box-shadow: 10px 10px;
  color: antiquewhite;
}
@media (max-width: 600px) {
  .r-info {
    padding: 3em;
    font-size: 3vmin;
    align-items: center;
    justify-content: center;

    background-color: #078bb3;
    margin-top: 25em;
    border: 1px solid transperent;
    border-radius: 120px;
    box-shadow: 10px 10px;
    color: antiquewhite;
  }
}
