.B-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin-top: 3em;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px; /* Add some padding to the sides so buttons don't touch the edge of the screen */
}

.buttons button {
  background-color: #078bb3;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 3em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid transperent;
  border-radius: 120px;
  box-shadow: 10px 10px black;
}
.buttons button:hover {
  color: #044559;
  cursor: pointer;
  box-shadow: 0 0 10px #054b61;
  transition: 200ms;
}

.buttons button:active {
  background-color: navy;
}

.buttons button:focus {
  outline: none;
}
@media (max-width: 650px) {
  .buttons button {
    font-size: 1.5em;
  }
}
