.Projects {
  width: 90vw;
  height: 35vh;
  display: flex;
  flex-wrap: wrap;
  margin-top: 4em;
  align-items: center;
  justify-content: space-evenly;
  border: 7px;
  border-radius: 120px;
  background-color: bisque;
}
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
.Weather-App {
  width: 29%;
  height: 10vh;
  border: 7px solid;
  border-radius: 120px;
  contain: strict;
  border-color: #078bb3;
  transition: transform 0.5s ease-in-out;
  background-color: white;
  padding: 0.5em;
}
.Weather-App:hover {
  /* animation: float 2s infinite ease-in-out; */
  /* box-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff,
    0 0 40px #00ffff; */
  border: 1px solid transperent;
  border-radius: 120px;
  box-shadow: 10px 10px;
  transition: 200ms;
}

img {
  height: 10vmin;
  width: 10vmin;
}
.isb {
  height: 10vmin;
  width: 13vmin;
}
.Info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wApp {
  width: 50%;
  align-items: center;
  text-align: center;
  color: #078bb3;
  font-weight: bolder;
}
@keyframes slideIn {
  0% {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

/* .myStyledModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: antiquewhite;
  border-radius: 15px;
  padding: 20px;
  animation: slideIn 0.5s ease-out;
  
} */
.myStyledModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  max-width: 600px;
  max-height: 800px;
  overflow: auto;
  background-color: antiquewhite;
  border-radius: 15px;
  padding: 20px;
  animation: slideIn 0.5s ease-out;
  /* Other styles as needed */
}
@media screen and (max-width: 600px) {
  .myStyledModal {
    width: 90%;
    height: 90%;
    padding: 10px;
  }
}
@media (max-width: 550px) {
  .Projects {
    height: 45vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .Weather-App {
    width: 60vw;
    height: 7vh;
    margin-bottom: 1em;
  }
  img {
    height: 10vmin;
    width: 10vmin;
  }
  .isb {
    height: 10vmin;
    width: 13vmin;
  }
}
.website-button {
  background-color: #008cba;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  transition-duration: 0.4s;
  font-size: 1rem;
}
.closeModalButton {
  background-color: #0099cc;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.closeModalButton:hover {
  background-color: #006699;
}
/* @media (max-width: 900px) {
  img {
    padding: 1em;
    height: 10vmin;
    width: 10vmin;
  }
  .isb {
    height: 10vmin;
    width: 10vmin;
  }
} */
